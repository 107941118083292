import { useCallback, useMemo } from "react";

import useSWR, { useSWRConfig } from "swr";

import { applicationEndpoints } from "@api/Endpoints";
import { AssociatedApplicationRoles } from "@users/applicationuser/models/ApplicationRole";

import deleteData from "@hooks/useDeleteData";
import { putData } from "@hooks/usePutData";
import { fetcher } from "@utils/swrUtils";

import { UserApplicationsResponse } from "../models/UserApplicationsResponse";

export function useGetApplicationRoles(applicationId: string) {
  const url = applicationEndpoints.applicationRoles(applicationId);

  const { data, error, isLoading } = useSWR<AssociatedApplicationRoles, Error>(
    url,
    fetcher
  );

  return useMemo(
    () => ({
      applicationRoles: data?.applicationRoles ?? [],
      limit: data?.numberOfRoles ?? 0,
      allowUpdate: data?.updateUserAllowed ?? false,
      isLoading,
      error
    }),
    [data, isLoading, error]
  );
}

export function useInviteApplicationUser() {
  const { mutate } = useSWRConfig();

  const invite = useCallback(
    async (
      applicationId: string,
      userId: string,
      applicationRoles: string[]
    ) => {
      const url = applicationEndpoints.updateOrDeleteApplicationUsers(
        applicationId,
        userId
      );

      const result = await putData<{ applicationRoles: string[] }>(url, {
        applicationRoles: applicationRoles
      });
      await mutate(applicationEndpoints.applicationUsers(applicationId));

      await mutate(applicationEndpoints.userApplications(userId));
      return result;
    },
    [mutate]
  );

  return useMemo(() => ({ invite }), [invite]);
}

export function useUserApplications(userId: string) {
  const url = applicationEndpoints.userApplications(userId);

  const { data, error, isLoading } = useSWR<UserApplicationsResponse, Error>(
    url,
    fetcher
  );

  return useMemo(
    () => ({ userApplications: data?.applications, isLoading, error }),
    [data, isLoading, error]
  );
}

export function useRemoveUserApplication() {
  const { mutate } = useSWRConfig();
  const deleteApp = useCallback(
    async (applicationId: string, userId: string) => {
      const url = applicationEndpoints.updateOrDeleteApplicationUsers(
        applicationId,
        userId
      );
      const result = await deleteData(url);
      await mutate(applicationEndpoints.applicationUsers(applicationId));
      return result;
    },
    [mutate]
  );

  return useMemo(() => ({ deleteApp }), [deleteApp]);
}
