import { FunctionComponent, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Input } from "@destination/components";

import { isRegExMatch } from "@validators/RegExValidator";

import { useInput } from "@hooks/useInput";

interface Props {
  id: string;
  label: string;
  value: string | undefined;
  onInputChange?: (
    newValue: string,
    isValid: boolean,
    errorMessage?: string
  ) => void;
  disabled?: boolean;
}

const UserEmail: FunctionComponent<Props> = ({
  id,
  label,
  value,
  onInputChange,
  disabled
}) => {
  const { t } = useTranslation();

  const initialValue = useMemo(() => value, [value]);

  const {
    value: email,
    isValid,
    errorMessage,
    valueChangeHandler
  } = useInput(
    [
      isRegExMatch(/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g, () =>
        t("validation.string.invalid-email", {
          fieldName: label
        })
      )
    ],
    initialValue
  );

  return (
    <Input
      type="text"
      id={id}
      data-testid={id}
      label={label}
      error={!isValid}
      helperText={!isValid ? errorMessage : undefined}
      value={email}
      disabled={disabled}
      onChange={event => {
        const { value, isValid, errorMessage } = valueChangeHandler(
          event.target.value?.trim()
        );
        if (onInputChange) onInputChange(value, isValid, errorMessage);
      }}
    />
  );
};

export default UserEmail;
